<template>
  <div>
    <br>
    <v-row
      justify="center"
      justify-md="end"
    >
      <v-col
        cols="3"
        md="1"
      >
        <v-fab-transition>
          <v-btn
            color="orange"
            fab
            large
            class="v-btn--example"
            @click="openedAddDialog = !openedAddDialog"
          >
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-fab-transition>
      </v-col>
    </v-row>
    <add-product-classification v-model="openedAddDialog" />
  </div>
</template>

<script>
  import AddProductClassification from './AddProductClassification.vue'
  export default {
    name: 'ProductLineTableTop',
    components: {
      AddProductClassification,
    },
    data () {
      return {
        openedAddDialog: false,
      }
    },
  }
</script>
