<template>
  <v-dialog
    v-model="dialog"
    max-width="500"
  >
    <v-card>
      <v-card-title>Editar clasificacion de productos</v-card-title>
      <edit-product-classification-form
        :product-classification="productClassification"
        @close="dialog = false"
      />
    </v-card>
  </v-dialog>
</template>

<script>
  import EditProductClassificationForm from './EditProductClassificationForm'
  export default {
    name: 'EditProductLineDialog',
    components: {
      EditProductClassificationForm,
    },
    props: {
      productClassification: {
        type: Object,
        default () {
          return {}
        },
      },
      value: {
        type: Boolean,
        default () {
          return false
        },
      },
    },
    computed: {
      dialog: {
        get () {
          return this.value
        },
        set (v) {
          this.$emit('input', v)
        },
      },
    },
  }
</script>
