<template>
  <product-classification-table />
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import ProductClassificationTable from '../components/product-classification/ProductClassificationTable.vue'
  export default {
    name: 'AdminWarehouses',
    components: {
      ProductClassificationTable,
    },
    computed: {
      ...mapState(['user', 'actionsParentBusiness']),
    },

    async created () {
      await this.fetchProductClassification({ id: this.user.businessId || this.actionsParentBusiness })
    },

    methods: {
      ...mapActions([
        'fetchProductClassification',
      ]),
    },
  }
</script>
